<template>
	<div class="customeranalyBox" v-loading='Loading'>
<!-- 		<div class="divCommon" style="padding: 15px;background: #fff;">
			<div class="title">RFM模型分析</div>
			<div class="timeSelect">
				<div class="left">
					<span>最近付款时间在</span>
					<el-date-picker v-model="Datevalue" type="date" value-format="yyyy-MM-dd" :picker-options="pickerOptionsDate"
					 placeholder="选择日期" style="margin: 0 10px;">
					</el-date-picker>
					<span>前一年的成交客户数据</span>
				</div>
				<div class="right">
					<el-button type="primary" size="small" @click="searchRFM">查询</el-button>
				</div>
			</div>
			<div class="choseindicat">
				<div class="top">
					<span>选择指标：</span>
					<el-radio v-model="Custonumradio" label="1">客户数/占比</el-radio>
					<el-radio v-model="Custonumradio" label="2">累计支付金额</el-radio>
					<el-radio v-model="Custonumradio" label="3">客单价</el-radio>
				</div>
				<div class="table" style="margin-top: 15px;">
					<el-table :data="tableData" style="width: 100%" show-summary :summary-method="getSummaries">
						<el-table-column prop="Rtime" label="消费时间(R)/频次(F)"></el-table-column>
						<el-table-column prop="num1" label="F=1"></el-table-column>
						<el-table-column prop="num2" label="F=2"></el-table-column>
						<el-table-column prop="num3" label="F=3"></el-table-column>
						<el-table-column prop="num4" label="F=4"></el-table-column>
						<el-table-column prop="num5" label="F>=5"></el-table-column>
						<el-table-column prop="sum" label="行合计">
							<template scope="scope">
								{{scope.row.sum = scope.row.num1 + scope.row.num2 + scope.row.num3 + scope.row.num4 + scope.row.num5}}
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
		</div> -->
		<!-- //消费能力分析 -->
		<div class="divCommon" style="padding: 15px;background: #fff;margin-top: 15px;">
			<div class="title">消费能力分析</div>
			<div class="timeSelect">
				<div class="left">
					<span>付款时间：</span>
					<el-date-picker v-model="customizeTime" @change="ChangeCustomize" :picker-options="CustomizeOptionsDate" type="daterange"
					 range-separator="至" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<label class="label" style="margin-left: 15px;">新老客户：</label>
					<el-select v-model="CustomId">
						<el-option v-for="item in Customlist" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-button type="primary" size="small" style="margin-left: 12px;" @click="SearchcusAbility">查询</el-button>
				</div>
			</div>
			<div class="choseindicat">
				<div style="margin-bottom: 15px;">支付金额分布图</div>
				<div class="top">
					<span>选择指标：</span>
					<el-radio v-model="Custompayadio" label="1" @change="Changeselect">客户数</el-radio>
					<el-radio v-model="Custompayadio" label="2" @change="Changeselect">支付总额</el-radio>
					<el-radio v-model="Custompayadio" label="3" @change="Changeselect">商品支付件数</el-radio>
				</div>
				<div class="echartCustomBox">
					<echart-customanaly :echartanalydata='echartanalydata'></echart-customanaly>
				</div>
			</div>
			<div class="table_box" style="margin-top: 15px;">
				<div class="title" style="margin-bottom: 15px;">支付金额分布明细</div>
				<el-table :data="tablepayData" style="width: 100%">
					<el-table-column prop="squareNum" label="累计支付金额区间"></el-table-column>
					<!-- 0全部  1新客户  2老客户 -->
					
					<el-table-column label="客户数">
						<template scope="scope">
							<div v-if='CustomId == 0'>{{Math.abs(scope.row.NewCustomerCount + scope.row.OldCustomerCount)}}</div>
							<div v-if='CustomId == 1'>{{Math.abs(scope.row.NewCustomerCount)}}</div>
							<div v-if='CustomId == 2'>{{Math.abs(scope.row.OldCustomerCount)}}</div>
						</template>
					</el-table-column>
					<el-table-column label="客户数占比">
						<template scope="scope">
							<div v-if='CustomId == 0'>
								<div v-if='allCustomeNum!=0'>
									{{(Math.abs(scope.row.NewCustomerCount + scope.row.OldCustomerCount)/allCustomeNum*100).toFixed(2) + '%'}}
								</div>
							</div>
							<div v-if='CustomId == 1'>
								<div v-if='allnewCustomNum!=0'>
									{{(Math.abs(scope.row.NewCustomerCount)/allnewCustomNum*100).toFixed(2) + '%'}}
								</div>
							</div>
							<div v-if='CustomId == 2'>
								<div v-if='alloldCustomNum!=0'>
									{{(Math.abs(scope.row.OldCustomerCount)/alloldCustomNum*100).toFixed(2) + '%'}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="商品支付件数">
						<template scope="scope">
							<div v-if="CustomId == 0">{{Math.abs(scope.row.NewCustomerPayProductCount + scope.row.OldCustomerPayProductCount)}}</div>
							<div v-if="CustomId == 1">{{Math.abs(scope.row.NewCustomerPayProductCount)}}</div>
							<div v-if="CustomId == 2">{{Math.abs(scope.row.OldCustomerPayProductCount)}}</div>
						</template>
					</el-table-column>
					
					<el-table-column label="人均支付件数">
						<template scope="scope">
							<div v-if='CustomId == 0'>
								<div v-if='(scope.row.NewCustomerCount + scope.row.OldCustomerCount)!=0'>
									{{((scope.row.NewCustomerPayProductCount+scope.row.OldCustomerPayProductCount)/(scope.row.NewCustomerCount + scope.row.OldCustomerCount)).toFixed(2)}}
								</div>
							</div>
							
							<div v-if="CustomId == 1">
								<div v-if='scope.row.NewCustomerCount != 0'>
									{{((scope.row.NewCustomerPayProductCount)/(scope.row.NewCustomerCount)).toFixed(2)}}
								</div>
							</div>
							<div v-if='CustomId == 2'>
								<div v-if='scope.row.OldCustomerCount != 0'>
									{{((scope.row.OldCustomerPayProductCount)/(scope.row.OldCustomerCount)).toFixed(2)}}
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="支付总额(元)">
						<template scope="scope">
							<div v-if='CustomId == 0'>
								{{Math.abs(scope.row.NewCustomerPayMoney + scope.row.OldCustomerPayMoney).toFixed(2)}}
							</div>
							<div v-if='CustomId == 1'>
								{{Math.abs(scope.row.NewCustomerPayMoney)}}
							</div>
							<div v-if='CustomId == 2'>
								{{Math.abs(scope.row.OldCustomerPayMoney)}}
							</div>
							
						</template>
					</el-table-column>
					<!-- allnewCustomNum:0,
					//所有老成交客户数
					alloldCustomNum:0,
					allnewProductCount:0,
					//所有老客户件数
					alloldProductCount:0,
					//所有新客户支付总额
					allnewPaymoney:0,
					//所有老客户支付总额
					alloldPaymoney:0, -->
					<el-table-column label="支付总额占比">
						<template scope="scope">
							<div v-if='CustomId == 0'>
								<div v-if="allPayMoney!=0">
									{{(Math.abs(scope.row.NewCustomerPayMoney + scope.row.OldCustomerPayMoney)/allPayMoney*100).toFixed(2) + '%'}}
								</div>
							</div>
							<div v-if='CustomId == 1'>
								<div v-if='allnewPaymoney!=0'>
									{{(Math.abs(scope.row.NewCustomerPayMoney)/allnewPaymoney*100).toFixed(2) + '%'}}
								</div>
							</div>
							<div v-if='CustomId == 2'>
								<div v-if='alloldPaymoney!=0'>
									{{(Math.abs(scope.row.OldCustomerPayMoney)/alloldPaymoney*100).toFixed(2) + '%'}}
								</div>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<!-- //消费频次分析 -->
		<div class="divCommon" style="padding: 15px;background: #fff;margin-top: 15px;">
			<div class="title">消费频次分析</div>
			<div class="timeSelect">
				<div class="left">
					<span>付款时间：</span>
					<el-date-picker v-model="customizeTime1" @change="ChangeCustomize1" :picker-options="CustomizeOptionsDate" type="daterange"
					 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<label class="label" style="margin-left: 15px;">新老客户：</label>
					<el-select v-model="CustomId1">
						<el-option v-for="item in Customlist" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-button type="primary" size="small" style="margin-left: 12px;" @click="searchRequce">查询</el-button>
				</div>
			</div>
			<div class="choseindicat">
				<div style="margin-bottom: 15px;">购买次数分布图</div>
				<div class="top">
					<span>选择指标：</span>
					<el-radio v-model="Custompayadio1" label="1" @change="Changerequce">客户数</el-radio>
					<el-radio v-model="Custompayadio1" label="2" @change="Changerequce">支付总额</el-radio>
					<el-radio v-model="Custompayadio1" label="3" @change="Changerequce">商品支付件数</el-radio>
				</div>
				<div class="echartBox">
					<echart-buycount :echarbuycountdata='echarbuycountdata'></echart-buycount>
				</div>
			</div>
			<div class="table_box" style="margin-top: 15px;">
				<div class="title" style="margin-bottom: 15px;">购买次数分布明细</div>
				<el-table :data="tableCountData" style="width: 100%">
					<el-table-column prop="BuyRemak" label="购买次数"></el-table-column>
					<el-table-column prop="CustomerCount" label="客户数"></el-table-column>
					<el-table-column prop="CustomerProportion" label="客户数占比">
						<template scope="scope">
							<div>{{scope.row.CustomerProportion + '%'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="ProductCount" label="商品支付件数"></el-table-column>
					<el-table-column prop="CustomerPerBuyCount" label="人均支付件数"></el-table-column>
					<el-table-column prop="PayMoney" label="支付总额(元)"></el-table-column>
					<el-table-column prop="AllPayMoneyProportion" label="支付总额占比">
						<template scope="scope">
							<div>{{scope.row.AllPayMoneyProportion + '%'}}</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<!-- //回购周期分析 -->
		<div class="divCommon" style="padding: 15px;background: #fff;margin-top: 15px;">
			<div class="title">回购周期分析</div>
			<div class="timeSelect" style="flex-direction: column;justify-content: flex-start;align-items: flex-start;">
				<div class="left">
					<span>付款时间：</span>
					<el-date-picker v-model="customizeTime2" @change="ChangeCustomize2" :picker-options="CustomizeOptionsDate" type="daterange"
					 range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
					</el-date-picker>
					<label class="label" style="margin-left: 15px;">新老客户：</label>
					<el-select v-model="CustomId2">
						<el-option v-for="item in Customlist" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>

					<el-button type="primary" size="small" style="margin-left: 12px;" @click="searchPayPeriod">查询</el-button>
				</div>
				<div style="margin-top: 15px;">经统计，平均回购统计为{{AvgRepeatPayPeriod}}天</div>
			</div>
			<div class="choseindicat">
				<div style="margin-bottom: 15px;">回购周期分布明细</div>
				<div class="top" style="display: flex;">
					<div class="left">
						<span>回购周期间隔：</span>
						<el-radio v-model="backintervalradio" label="0" @change="Changeperiod">10天</el-radio>
						<el-radio v-model="backintervalradio" label="1" @change="Changeperiod">30天</el-radio>
					</div>
					<div class="left" style="margin-left: 20px;">
						<span>指标选择：</span>
						<el-radio v-model="Indexselect" label="1" @change="Changeperiod">支付金额(M)</el-radio>
						<el-radio v-model="Indexselect" label="2" @change="Changeperiod">购买频次(F)</el-radio>
					</div>
				</div>
			</div>
			<div class="table_box" style="margin-top: 35px;">
				<el-table :data="tableperiodData" style="width: 100%">
					<el-table-column prop="RepeatPeriodName" label="回购周期" :key='1'></el-table-column>
					<el-table-column prop="MemberCount" label="客户数" :key='2'></el-table-column>
					<el-table-column prop="MemberCountRate" label="客户数占比" :key='3'>
						<template scope="scope">
							<div>{{scope.row.MemberCountRate + '%'}}</div>
						</template>
					</el-table-column>
					<el-table-column prop="PayMoney" label="支付金额" :key='4' v-if="Indexselect==1"></el-table-column>
					<el-table-column prop="AvgPayMoney" label="人均消费金额" :key='5' v-if="Indexselect==1"></el-table-column>
					<el-table-column prop="MemberCountCumulativeRate"  label="累计占比" :key='6' v-if="Indexselect==2"></el-table-column>
					<el-table-column prop="RepeatPayOneRate" label="回购1次" :key='6' v-if="Indexselect==2"></el-table-column>
					<el-table-column prop="RepeatPayTwoRate" label="回购2次" :key='7' v-if="Indexselect==2"></el-table-column>
					<el-table-column prop="RepeatPayThreeRate" label="回购3次" :key='8' v-if="Indexselect==2"></el-table-column>
					<el-table-column prop="RepeatPayFourRate" label="回购4次" :key='9' v-if="Indexselect==2"></el-table-column>
					<el-table-column prop="RepeatPayGreaterThanFiveRate" label="回购5次及以上" :key='10' v-if="Indexselect==2"></el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
	import echartCustomanaly from '../Customtransanaly/echartCustomanaly'
	import echartBuycount from '../Customtransanaly/echartBuycount'
	import {
		consumeAbilityData,
		consumeFrequencyData,
		memberRepeatPaydata
	} from '@/api/TurnTomySelf'
	export default {
		components: {
			echartCustomanaly,
			echartBuycount
		},
		data() {
			return {
				//所有新成交客户数
				allnewCustomNum:0,
				//所有老成交客户数
				alloldCustomNum:0,
				allnewProductCount:0,
				//所有老客户件数
				alloldProductCount:0,
				//所有新客户支付总额
				allnewPaymoney:0,
				//所有老客户支付总额
				alloldPaymoney:0,
				AvgRepeatPayPeriod:0,
				allCustomeNum: 0,
				allPayMoney: 0,
				allPayCount: 0,
				Loading: false,
				//回购周期数据
				tableperiodData: [],
				//回购周期间隔
				backintervalradio: '0',
				//指标选择
				Indexselect: '1',
				//购买次数
				echarbuycountdata:[],
				tableCountData: [],
				tablepayData: [],
				echartanalydata: [],
				//支付金额
				Custompayadio: '1',
				CustomId: 0,
				//消费频次
				Custompayadio1: '1',
				CustomId1: 0,
				//回购周期
				Custompayadio2: '1',
				CustomId2: 0,
				Customlist: [{
					value: 0,
					label: '全部客户'
				}, {
					value: 1,
					label: '新成交客户'
				}, {
					value: 2,
					label: '老成交客户'
				}],
				CustomizeOptionsDate: {
					disabledDate(time) {
					  return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
					}
				},
				customizeTime: [],
				//消费频次
				customizeTime1: [],
				//回购周期
				customizeTime2: [],

				Custonumradio: '1',
				Startdate: '',
				EndDate: '',
				//消费频次
				Startdate1: '',
				EndDate1: '',
				//回购周期
				Startdate2: '',
				EndDate2: '',
				Datevalue: '',
				pickerOptionsDate: {
					disabledDate(time) {
						return time.getTime() > Date.now() - 24 * 60 * 60 * 1000
					}
				},
				tableData: [{
					Rtime: 'R <= 30',
					num1: 100,
					num2: 200,
					num3: 300,
					num4: 400,
					num5: 500
				}, {
					Rtime: '30<R <= 90',
					num1: 100,
					num2: 200,
					num3: 300,
					num4: 400,
					num5: 500
				}]
			}
		},
		// computed: {
		// 	tableData() {
		// 		this.tableData.map((v, i) => {
		// 			v.sum = v.num1 + v.num2 + v.num3 + v.num4 + v.num5;
		// 		});
		// 		return this.tableData
		// 	}
		// },
		created() {
			//初始化RFM模型初始化
			this.getDateTime()
			//初始化自定义时间
			//自定义
			let time = new Date()
			let startTime = new Date(time - 1000 * 60 * 60 * 24 * 30)
			let lastTime = new Date(time - 1000 * 60 * 60 * 24)
			this.customizeTime = []
			this.customizeTime.push(startTime)
			this.customizeTime.push(lastTime)
			
			//消费频次
			this.customizeTime1 = []
			this.customizeTime1.push(startTime)
			this.customizeTime1.push(lastTime)
			
			//回购周期
			this.customizeTime2 = []
			this.customizeTime2.push(startTime)
			this.customizeTime2.push(lastTime)
			this.getCustomizeTime()
			//初始化消费能力
			this.getconsumeAbilityData()
			//初始化消费频次
			this.getconsumeFrequencyData()
			//初始化回购周期
			this.getmemberRepeatPaydata()
		},
		methods: {
			searchPayPeriod(){
				if(!this.Startdate2 && !this.EndDate2){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入查询时间'
					});
					return false
				}
				this.getmemberRepeatPaydata()
			},
			Changeperiod(){
				this.getmemberRepeatPaydata()
			},
			async getmemberRepeatPaydata(){
				this.Loading = true
				try{
					let data ={
						StartTime :this.Startdate2,
						EndTime:this.EndDate2 ,
						PeriodType:this.backintervalradio,
						MemberType:this.CustomId2
					}
					let result = await memberRepeatPaydata(data)
					if(result.IsSuccess){
						this.AvgRepeatPayPeriod = result.Result.AvgRepeatPayPeriod
					    this.tableperiodData = result.Result.RepeatPayPeriodList
					}
					
					console.log(result,'回购周期的数据')
				}catch(err){
					
				}finally{
					this.Loading = false
				}
			},
			SearchcusAbility() {
				if(!this.Startdate && !this.EndDate){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入查询时间'
					});
					return false
				}
				this.getconsumeAbilityData()
			},
			//改变消费能力
			Changeselect() {
				this.getconsumeAbilityData()
			},
			formatTen(num) {
				return num > 9 ? (num + "") : ("0" + num);
			},
			//格式化时间 YYYY-MM-DD/ YYYY-MM-DD hh:mm:ss
			formatDate(date) {
				date = new Date(date)
				let year = date.getFullYear();
				let month = date.getMonth() + 1;
				let day = date.getDate();
				return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
			},
			Changerequce(){
				this.getconsumeFrequencyData()
			},
			//查询消费频次
			searchRequce() {
				if(!this.Startdate1 && !this.EndDate1){
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入查询时间'
					});
					return false
				}
				this.getconsumeFrequencyData()
			},
			async getconsumeFrequencyData() {
				this.Loading = true
				try {
					let data = {
						CustomerType: this.CustomId1,
						PayStartTime: this.Startdate1,
						PayEndTime: this.EndDate1 
					}
					let result = await consumeFrequencyData(data)
					let newCustomeNum = []
					let oldCustomeNum = []
					let newPayCount = []
					let oldPayCount = []
					let newPaymoney = []
					let oldPaymoney = []
					if (result.IsSuccess) {
						result.Result.map(item => {
							newCustomeNum.push(item.NewCustomerCount)
							oldCustomeNum.push(item.OldCustomerCount)
							newPayCount.push(item.NewProductCount)
							oldPayCount.push(item.OldProductCount)
							newPaymoney.push(item.NewAllPayMoney)
							oldPaymoney.push(item.OldAllPayMoney)
						})
						// 1 客户数  2 支付总额 3 商品支付数
						if (this.Custompayadio1 == 1) {
							if (this.CustomId1 == 0) {
								this.echarbuycountdata = [{
									Name: '新客户',
									value: newCustomeNum
								}, {
									Name: '老客户',
									value: oldCustomeNum
								}]
							}
							if (this.CustomId1 == 1) {
								this.echarbuycountdata = [{
									Name: '新客户',
									value: newCustomeNum
								}]
							}
							if (this.CustomId1 == 2) {
								this.echarbuycountdata = [{
									Name: '老客户',
									value: oldCustomeNum
								}]
							}
						}
						//支付金额
						if (this.Custompayadio1 == 2) {
							if (this.CustomId1 == 0) {
								this.echarbuycountdata = [{
									Name: '新客户',
									value: newPaymoney
								}, {
									Name: '老客户',
									value: oldPaymoney
								}]
							}
							if (this.CustomId1 == 1) {
								this.echarbuycountdata = [{
									Name: '新客户',
									value: newPaymoney
								}]
							}
							if (this.CustomId1 == 2) {
								this.echarbuycountdata = [{
									Name: '老客户',
									value: oldPaymoney
								}]
							}
						}
						if (this.Custompayadio1 == 3) {
							if (this.CustomId1 == 0) {
								this.echarbuycountdata = [{
									Name: '新客户',
									value: newPayCount
								}, {
									Name: '老客户',
									value: oldPayCount
								}]
							}
							if (this.CustomId1 == 1) {
								this.echarbuycountdata = [{
									Name: '新客户',
									value: newPayCount
								}]
							}
							if (this.CustomId1 == 2) {
								this.echarbuycountdata = [{
									Name: '老客户',
									value: oldPayCount
								}]
							}
						}
						this.tableCountData = result.Result
					}
					 
					console.log(this.echarbuycountdata, '消费频次数据呢')
				} catch (err) {

				} finally {
					this.Loading = false
				}
			},
			async getconsumeAbilityData() {
				// console.log(this.customizeTime,'没有发生变化吗时间')
				try {
					this.Loading = true
					let data = {
						StartTime:this.Startdate,
						EndTime: this.EndDate
					}
					let result = await consumeAbilityData(data)
					let newCustomeNum = []
					let oldCustomeNum = []
					let newPayCount = []
					let oldPayCount = []
					let newPaymoney = []
					let oldPaymoney = []
					if (result.IsSuccess) {
						result.Result.map(item => {
							newCustomeNum.push(item.NewCustomerCount)
							oldCustomeNum.push(item.OldCustomerCount)
							newPayCount.push(item.NewCustomerPayProductCount)
							oldPayCount.push(item.OldCustomerPayProductCount)
							newPaymoney.push(item.NewCustomerPayMoney)
							oldPaymoney.push(item.OldCustomerPayMoney)
						})
						//初始化数据
						if (this.Custompayadio == 1) {
							if (this.CustomId == 0) {
								this.echartanalydata = [{
									Name: '新客户',
									value: newCustomeNum
								}, {
									Name: '老客户',
									value: oldCustomeNum
								}]
							}
							if (this.CustomId == 1) {
								this.echartanalydata = [{
									Name: '新客户',
									value: newCustomeNum
								}]
							}
							if (this.CustomId == 2) {
								this.echartanalydata = [{
									Name: '老客户',
									value: oldCustomeNum
								}]
							}
						}
						//支付金额
						if (this.Custompayadio == 2) {
							if (this.CustomId == 0) {
								this.echartanalydata = [{
									Name: '新客户',
									value: newPaymoney
								}, {
									Name: '老客户',
									value: oldPaymoney
								}]
							}
							if (this.CustomId == 1) {
								this.echartanalydata = [{
									Name: '新客户',
									value: newPaymoney
								}]
							}
							if (this.CustomId == 2) {
								this.echartanalydata = [{
									Name: '老客户',
									value: oldPaymoney
								}]
							}
						}
						if (this.Custompayadio == 3) {
							if (this.CustomId == 0) {
								this.echartanalydata = [{
									Name: '新客户',
									value: newPayCount
								}, {
									Name: '老客户',
									value: oldPayCount
								}]
							}
							if (this.CustomId == 1) {
								this.echartanalydata = [{
									Name: '新客户',
									value: newPayCount
								}]
							}
							if (this.CustomId == 2) {
								this.echartanalydata = [{
									Name: '老客户',
									value: oldPayCount
								}]
							}
						}

						this.tablepayData = result.Result.map(item => {
							item.squareNum = this.getMoneycount(item.StatisRangeSequence)
							return item
						})
						let sum1 = 0
						let sum2 = 0
						let sum3 = 0
						let sum4 = 0
						let sum5 = 0
						let sum6 = 0
						let sum7 = 0
						let sum8 = 0
						let sum9 = 0
						this.tablepayData.map(item => {
							sum1 += (item.NewCustomerCount + item.OldCustomerCount)
							sum2 += (item.NewCustomerPayMoney + item.OldCustomerPayMoney)
							sum3 += (item.NewCustomerPayProductCount + item.OldCustomerPayProductCount)
							sum4 += (item.NewCustomerCount)
							sum5 += (item.OldCustomerCount)
							sum6 += (item.NewCustomerPayProductCount)
							sum7 += (item.OldCustomerPayProductCount)
							sum8 += (item.NewCustomerPayMoney)		
							sum9 += (item.OldCustomerPayMoney)
						})
						//所有客户数
						this.allCustomeNum = Number(sum1)
						//所有商品件数
						this.allPayMoney = Number(sum2)
						//所有支付总额
						this.allPayCount = Number(sum3)
						//所有新成交客户数
						this.allnewCustomNum = Number(sum4)
						//所有老成交客户数
						this.alloldCustomNum  = Number(sum5)
						//所有新客户件数
						this.allnewProductCount =  Number(sum6)
						//所有老客户件数
						this.alloldProductCount =  Number(sum7)
						//所有新客户支付总额
						this.allnewPaymoney = Number(sum8)
						//所有老客户支付总额
						this.alloldPaymoney = Number(sum9)
						
					}
				} catch (err) {

				} finally {
					this.Loading = false
				}
			},
			getMoneycount(Num) {
				if (Num >= 11 && Num < 20) {
					let b = Num - 10
					return b * 500 + '-' + (b * 500 + 500) + '元'
				} else if (Num == 20) {
					return '5000元以上'
				} else {
					let a = Num - 1
					return a * 50 + '-' + (a * 50 + 50) + '元'
				}
			},
			//搜索RFM
			searchRFM() {

			},
			//自定义
			getCustomizeTime() {
				//消费能力
				this.Startdate = this.formatDate(this.customizeTime[0])
				this.EndDate = this.formatDate(this.customizeTime[1])
				//消费频次
				this.Startdate1 = this.formatDate(this.customizeTime1[0])
				this.EndDate1 = this.formatDate(this.customizeTime1[1])
				//回购周期
				this.Startdate2 = this.formatDate(this.customizeTime2[0])
				this.EndDate2 = this.formatDate(this.customizeTime2[1])
			},
			//自定义
			ChangeCustomize() {
				console.log(this.customizeTime,'清空之后的数据9999')
				if(this.customizeTime == null){
					this.Startdate = ''
					this.EndDate = ''
				}
				else{
					this.Startdate = this.customizeTime[0]
					this.EndDate = this.customizeTime[1]
				}
				
			},
			//自定义
			ChangeCustomize1() {
				if(this.customizeTime1 == null){
					this.Startdate1 = ''
					this.EndDate1 = ''
				}
				else{
					this.Startdate1 = this.customizeTime1[0]
					this.EndDate1 =this.customizeTime1[1]
				}
				
				
			},
			//自定义
			ChangeCustomize2() {
				if(this.customizeTime2 == null){
					this.Startdate2  = ''
					this.EndDate2 = ''
				}
				else{
					this.Startdate2 = this.customizeTime2[0]
					this.EndDate2 = this.customizeTime2[1]
				}
				
				
			},
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '列合计';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						sums[index] += ' 人';
					} else {
						sums[index] = 'N/A';
					}
				});
				// console.log(sums, '567890')
				return sums;
			},
			getDateTime() {
				let time = new Date()
				this.Datevalue = this.formatDate(new Date(time - 1000 * 60 * 60 * 24))
			},
			// formatDate(date) {
			// 	var year = date.getFullYear();
			// 	var month = date.getMonth() + 1;
			// 	var day = date.getDate();
			// 	var h = date.getHours();
			// 	//获取当前分钟数(0-59)
			// 	var m = date.getMinutes();
			// 	//获取当前秒数(0-59)
			// 	var s = date.getSeconds();

			// 	return year + "-" + this.formatTen(month) + "-" + this.formatTen(day);

			// },
			// formatTen(num) {
			// 	return num > 9 ? (num + "") : ("0" + num);
			// },
		},
	}
</script>

<style lang="less" scoped>
	.customeranalyBox {
		.divCommon {
			font-size: 13px;

			.timeSelect {
				display: flex;
				padding: 10px;
				background: #F5F5F5;
				margin-top: 15px;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 15px;
			}

			.echartCustomBox {
				border: 1px solid #ddd;
				padding: 10px;
				width: 100%;
				height: 700px;
				margin-top: 10px;
				background-color: #fff;
			}

			.echartBox {
				border: 1px solid #ddd;
				padding: 10px;
				width: 100%;
				height: 500px;
				margin-top: 10px;
				background-color: #fff;
			}
		}
	}
</style>
