<template>
	<div ref="echartBuycount" style="width:100%;height:100%;">
	</div>
</template>

<script>
	import echarts from 'echarts'
	export default {
		name: 'echartBuycount',
		props: {
			echarbuycountdata: {
				type: Array,
				default: []
			}

		},
		data() {
			return {}
		},
		mounted() {
			this.getEchart();
			window.addEventListener('resize', this.resize)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.resize)
		},
		watch: {
			echarbuycountdata(n, o) {
				this.$nextTick(() => {
					this.getEchart();
				});
			},
		},
		methods: {
			resize(){
				this.myChart.resize()
			},
			getEchart() {
				this.myChart = echarts.init(this.$refs.echartBuycount)
				this.legend = []
				this.series = []
				this.echarbuycountdata.map(t => {
					this.legend.push(t.Name)
					let series = {
						name: t.Name,
						type: 'bar',
						data: t.value,
						stack: '总量',
						// smooth: true,
						label: {
							normal: {
								show: true,
								position: 'insideRight'
							}
						},
					}
					if(t.Name == '新客户'){
						series.itemStyle = {
							normal: {
							    color: '#60acfc',
							    lineStyle: {
							        color: '#60acfc'
							    }
							}
						}
					}
					
					if(t.Name == '老客户'){
						series.itemStyle = {
						  normal: {
						      color: '#30f643',
						      lineStyle: {
						          color: '#30f643'
						      }
						  }
						}
					}
					this.series.push(series)
				})
				let option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: { // 坐标轴指示器，坐标轴触发有效
							type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
						}
					},
					legend: {
						data: this.legend
					},
					grid: {
						left: '2%',
						top: '15%',
						right: '3%',
						bottom: '4%',
						containLabel: true
					},
					xAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						type: 'value',
						axisLabel: {
							show: false
						},
					},
					yAxis: {
						axisLine: {
							show: false
						},
						axisTick: {
							show: false
						},
						type: 'category',
						data: ['购买1次', '购买2次', '购买3次', '购买4次', '购买5次及以上'],
					},
					series: this.series


				};
				this.myChart.setOption(option,true);
			}
		}
	}
</script>

<style>
</style>
